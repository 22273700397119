<template>
  <MitchOS :taskGroupBindings="['chat', 'system']">

    <!-- TODO: need to disable hotkeys on user page. also bind smaller tasks -->

    <!-- DEBUG -->
    <!-- <Debug/> -->

    <!-- CHAT MESSAGES -->
    <ChatMessages
      class="cover"
      :chat-guide="true"
      :top-to-bottom="false"
    />

  </MitchOS>
</template>

<script>
  import ChatMessages from "@/components/MitchOS/Programs/Chat/ChatMessages.vue";
  // import Debug from "@/components/MitchOS/Programs/Debug-v1/Debug.vue";
  import MitchOS from "@/components/MitchOS/MitchOS.vue";

  const manifest = composeManifest(
    [
      // './MitchOS/Programs/',
      "./fonts/",
      // './MitchOS/Transitions/' // works
      // './MitchOS/Transitions/assets/audio/'  doesn't work
    ],
    { ignore: [".mp3"] }
  );

  export default {
    name: "PopoutChat",
    components: {
      ChatMessages,
      // Debug,
      MitchOS,
    },
    props: {
      preloadComplete: {
        type: Boolean,
        required: false,
      },
    },
    /**
     * EMIT - REQUEST PRELOADER
     * Each view has its own set of assets to load
     */
    created() {
      this.$emit("request-preloader", {
        manifest,
      });
    },
  };
</script>

<style lang="scss" scoped>
  .mitch-os {
    background-color: #28282f;
  }

  .cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>